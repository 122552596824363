'use client';
/**
 * This component only renders if the application in preview mode.
 * The button associated with this component for disabling preview mode.
 */
export const PreviewAlert: React.FC = () => {
  const isCmsDomain = location?.ancestorOrigins?.contains(
    'https://app.contentful.com'
  );
  const exitPreview = async () => {
    await fetch('/api/disable-draft').then(() => {
      location.reload();
    });
  };
  return (
    !isCmsDomain && (
      <div className="preview-mode-alert">
        You are now running in preview mode.
        <button onClick={() => exitPreview()}>Click here</button> to exit.
      </div>
    )
  );
};

export default PreviewAlert;
