'use client';
import React, { useEffect } from 'react';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

type LivePreviewContainerProps = {
  children: React.ReactNode;
  preview: boolean;
};
/**
 * LivePreviewContainer wraps its children with the ContentfulLivePreviewProvider provided by the @contentful.
 * providing live preview functionality and inspector mode for Contentful CMS content.
 * The preview prop controls whether these features are enabled or not, allowing for flexibility in development and previewing of content.
 * locale: The locale to be used for previewing content, set to "en-US".
 * enableLiveUpdates: A boolean indicating whether live updates are enabled, based on the preview prop.
 * enableInspectorMode: A boolean indicating whether inspector mode is enabled, based on the preview prop.
 */
const LivePreviewContainer: React.FC<LivePreviewContainerProps> = ({
  children,
  preview
}) => {
  useEffect(() => {
    preview
      ? sessionStorage?.setItem('isEnabled', 'true')
      : sessionStorage?.removeItem('isEnabled');
    return () => {
      sessionStorage?.removeItem('isEnabled');
    };
  }, [preview]);

  return (
    <ContentfulLivePreviewProvider
      locale="en-US"
      enableLiveUpdates={preview}
      enableInspectorMode={preview}
    >
      {children}
    </ContentfulLivePreviewProvider>
  );
};

export default LivePreviewContainer;
